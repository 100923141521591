import React from 'react';
import './MenuItem.css';
const MenuItem = (props) => {
  return(  <div>

    <div className="wrapper">
      <div className="menu-item-title">
        <b>{props.ItemName}</b>
        <b>{props.ItemPrice}</b>
      </div>
      <div className="menu-item-description">
        <i>{props.ItemDescription}</i>
      </div>
    </div>


  </div>)

};

export default MenuItem;
