import React from 'react'
import "./Imagebox.css"
import Button from '../Button/Button'
const Imagebox = (props) => {

const ContainerStyle = {
   
    backgroundColor : props.color,
    

}

    return (

        <div style={ContainerStyle}>
        <div  id='Container'>
            <img src={props.Image} alt='stock'/>
            <div>
            <h1>{props.Text}</h1>
            <div id='Paragraph'>{props.Paragraph}
            <Button Text={props.ImageBoxButtonText} onClick = {props.ImageBoxButtonOnClick} marginTop = '5%' /></div>
            
            </div>
         
        </div>
        </div>
    )
}

export default Imagebox
