// Todo
// ask somebody to fix placeholder text
//menu looks like shit, REDO



import './App.css';
import Imagebox from './Imagebox/Imagebox';
import Navbar from './Navbar/Navbar';
import Splash from './Splash/Splash';
import MyForm from './MyForm/MyForm';
import React from 'react';
import MenuItem from './MenuItem/MenuItem';
import Modal from 'react-modal';


// import { useCallback } from 'react';
// import { useNavigate } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'


library.add(fab, faCheckSquare, faCoffee, faEnvelope, faPhone, faMapMarkerAlt)

const customStyles = {
     content: {
          backgroundColor: '#161616',
          color: '#fff',
     },
};

function App() {

     //const navigate = useNavigate();
     //const handleOnClick = useCallback(() => navigate('/Menu', { replace: true }), [navigate]);

     let subtitle;
     const [modalIsOpen, setIsOpen] = React.useState(false);

     function openModal() {
          setIsOpen(true);
     }

     function afterOpenModal() {
          // references are now sync'd and can be accessed.
          subtitle.style.color = '#f00';
     }

     function closeModal() {
          setIsOpen(false);
     }

     return (
          <div className="App">


               <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    ariaHideApp={false}
                    id="Modal"
                    contentLabel="Example Modal"
                    style={customStyles}
               >
                    <div>
                         <div id='Center'>
                              <h8>Menu</h8>
                         </div>
                         <hr />
                         <div id='MenuItems'>
                              <div id='Left'>
                                   <h8>Pizza</h8>
                                   <MenuItem ItemName="Cheezy Garlic" ItemPrice="16" ItemDescription="Cheese, Garlic" />
                                   <MenuItem ItemName="Margherita" ItemDescription="Tomato, Basil, Cheese" ItemPrice="18" />
                                   <MenuItem ItemName="Chicken" ItemDescription="Roast Chicken, Pineapple" ItemPrice="22" />
                                   <MenuItem ItemName="Hawaiian" ItemDescription="Ham, Pineapple, Cheese" ItemPrice="22" />
                                   <MenuItem ItemName="Vegetarian" ItemDescription="Spinach, Capsicum, Onion, Olives, Pineapple, Mushrooms, Tomatoes, Cheese" ItemPrice="22" />
                                   <MenuItem ItemName="Meat Lovers" ItemDescription="Bacon, Salami, Sausages, Onion, Cheese" ItemPrice="24" />
                                   <MenuItem ItemName="Salmon" ItemDescription="Salmon, Spinach, Onion, Aoli" ItemPrice="24" />
                                   <MenuItem ItemName="Supreme" ItemDescription="Bacon, Salami, Sausages, Pepperoni, Chicken, Onion, Cheese, Spinach, Pineapple, Mushrooms" ItemPrice="24" />

                              </div>
                              <div id='Right'>
                                   <h8>Tapas</h8>
                                   <MenuItem ItemName="Garlic Bread" ItemDescription="Bread with Garlic and Olive Oil" ItemPrice="8" />
                                   <MenuItem ItemName="Cheesy Garlic Bread" ItemDescription="Bread with Garlic, Olive Oil and Cheese" ItemPrice="10" />
                                   <MenuItem ItemName="Bruschetta" ItemDescription="Toasted Bread with Diced Tomato, Garlic, Basil and Olive Oil" ItemPrice="10" />
                                   <MenuItem ItemName="Satay Chicken Wings" ItemDescription="Marinated Chicken Wings with Peanut Satay Sauce" ItemPrice="12" />
                                   <MenuItem ItemName="BBQ Chicken Wings" ItemDescription="Marinated Chicken Wings with BBQ Sauce" ItemPrice="12" />
                                   <MenuItem ItemName="Soft Tacos" ItemDescription="Multiple Options" ItemPrice="15" />
                                   <MenuItem ItemName="Calamari" ItemDescription="Crumbed Calamari with Lemon" ItemPrice="10" />
                                   <MenuItem ItemName="Spring Rolls" ItemDescription="Served with Sweet Chilli Sauce" ItemPrice="10" />
                                   <MenuItem ItemName="Sweet Potato Chips" ItemDescription="Served with Aoli Sauce" ItemPrice="10" />
                                   <MenuItem ItemName="Chips" ItemDescription="Served with Tomato Sauce" ItemPrice="8" />
                              </div>
                         </div>
                         <hr />
                         <div id='MenuItems'>
                              <div id='Left'>
                                   <h8>Mains and Kids Meals</h8>
                                   <MenuItem ItemName="Fish and Chips" ItemDescription="Crumbed Baramundi, Chips, Tartare Sauce" ItemPrice="20" />
                                   <MenuItem ItemName="Vegie Salad" ItemDescription="Roast Vegtables, Quinoa, Balsamic Dressing on Mixed Greens" ItemPrice="15.50" />
                                   <MenuItem ItemName="Chicken Nuggets and Chips" ItemDescription="Chicken Nuggets, Chips, Tomato Sauce" ItemPrice="10" />
                                   <MenuItem ItemName="7 in Hawaiian" ItemDescription="Chicken, Pineapple, Ham, Cheese" ItemPrice="10" />
                                   <MenuItem ItemName="CheeseBurger" ItemDescription="CheeseBurger with Sauce and Chips" ItemPrice="10" />
                              </div>
                              <div id='Right'>
                                   <h8>Burger and Sandwiches</h8>
                                   <MenuItem ItemName="Chicken Deluxe Burger" ItemDescription="Chicken, Bacon, Cheese, Tomato, Onion, Lettuce, Mayo" ItemPrice="18" />
                                   <MenuItem ItemName="Veggie Burger" ItemDescription="Plant Based Patty, Lettuce, Tomato, Beetroot, Sweet Chilli Sauce" ItemPrice="18" />
                                   <MenuItem ItemName="Barra Burger" ItemDescription="Curmbed Baramundi, Lettuce, Tomato and Tartare Sauce" ItemPrice="20" />
                                   <MenuItem ItemName="Steak Sandwich" ItemDescription="Steak,Onion, Cheese, Lettuce Tomato, Tartare Sauce" ItemPrice="20" />
                                   <MenuItem ItemName="Works Burger" ItemDescription="Meat Patty, Lettuce, Tomato, Beetroot, Egg, Pinapple, Cheese, BBQ Sauce" ItemPrice="22" />
                                   <b>All Burgers and Sandwiches come with a serving of chips</b>
                              </div>
                         </div>
                    </div>





               </Modal>
               <Splash />
               <Navbar />


               <section id='About'>
                    <Imagebox
                         color={'#424242'}
                         Image='images/dough.jpg'
                         Text='About'
                         Paragraph="NorthShore Pizza Bar & Grill is a local resturaunt specialising in gourmet woodfire pizza and other Italian delights. Also offering a bar & grill, We are located in the heart of Mudjimba, Queensland. We are open for business from 7:30am-2pm, then 4-9pm Wednesday to Sunday, and we are happy to serve you."
                    />
               </section>

               <section id='Menu'>
                    <Imagebox Image='images/pizzawbeer.jpg'
                         Text="Menu"
                         color={'#2C2C2C'}
                         Paragraph="We offer a wide range of delicious pizzas, including our signature pizzas, and many other amazing items such as tacos, fish & chips, burgers, and salads not including our wide range of sides Please click below for our full menu"
                         ImageBoxButtonText="Full Menu"
                         ImageBoxButtonOnClick={openModal}
                    />
               </section>



               <section id='Catering'>
                    <Imagebox
                         Image="images/Catering.jpg"
                         Text='Catering'
                         color={"#161616"}
                         Paragraph="Our staff are experienced caterers who are experts in sourcing the freshest local ingredients that will make your event pop!

                         Before your big event kicks off hit the ground running with our premium catering service located in pristine Mudjimba!
                         
                         If you are not sure what you are looking for please browse our gallery for ideas or call us on 0739997543 or email us at TheLedgeMudjimba@mail.com
                         
                         We Hope To Serve You Soon!"
                         ImageBoxButtonText="Order Catering"
                         ImageBoxButtonOnClick={() => window.location.replace('#Contact')}
                    />
               </section>

               <section id='Contact'>

                    <MyForm />

               </section>

               <div>

               </div>

          </div>

     );
}

export default App;