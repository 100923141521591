import React from 'react'
import './Button.css'
import { motion } from 'framer-motion'



const Button = (props) => {



    // a style object for the button where i use a width prop as with
    const ButtonStyle = {
        width: props.width,
        marginTop: props.marginTop,

    }
  


    return (
        <div>


            
                <motion.button
                    onClick={props.onClick}
                    style={ButtonStyle}

                    className='save-button'
                    id='GenButton'
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                >
                    {props.Text}
                </motion.button>
          
            

        </div>
    )
}

export default Button
