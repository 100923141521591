import React from 'react'
import './Navbar.css'





const Navbar = () => {
    return (
        <div id='navbar'>
			<div id='InnerNavbar'>
				<div><a href="#About">About</a></div>
				<div><a href="#Menu">Menu</a></div>
				<div><a href="#Catering">Catering</a></div>
				<div><a href="#Contact">Contact Us</a></div>
				
			</div>
	
        </div>
    )
}

export default Navbar
