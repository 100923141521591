import React from 'react'
//css
import './Splash.css'
const Splash = () => {
    return (
        <div id='Splash'>
            <br>
            </br>
            <br></br>
            <h1>NorthShore Pizza, Bar & Grill</h1>
           <h3>Woodfire Pizzas & Calzones</h3>
            <h4>Craft beer on tap, cocktails, wine & spirits</h4>
            <p>7:30am-2pm, 4-9pm Wednesday to Sunday</p>
            <br></br>
                     </div>
    )
}

export default Splash
