import React from 'react'
import Button from "../Button/Button";
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Menu.css'
const Menu = () => {
    const navigate = useNavigate();
const handleOnClick = useCallback(() => navigate('/', {replace: true}), [navigate]);
    return (
        
        <div>
            <div id='MenuDiv'>
                
            
            <img className="MenuItem" src='images/menu1.png' alt='Top Menu'/>
            <img className="MenuItem" src='images/menu2.png' alt='Bottom Menu'/>
            <Button  Text='Home' onClick = {handleOnClick}  />
            </div>
        </div>
    )
}

export default Menu
