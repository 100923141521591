
import './MyForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../Button/Button';
function MyForm() {



  return (
    <div id="ContactForm">

      <h1>Contact Us</h1>

      <div id='icons'>
        <ul>
          <ul className="icons">
            <li><a href="mailto:theledgemudjimba@mail.com"><FontAwesomeIcon icon="envelope" /><span className="label"></span></a></li>
            <li><a href="tel:2111 6725"><span className="label"><FontAwesomeIcon icon="phone" /></span></a></li>
            <li><a href="https://www.instagram.com/northshoregourmetpizzabar/" target="_blank" rel='noopener noreferrer'><span className="label"><FontAwesomeIcon icon={["fab", "instagram"]} /></span></a></li>
            <br /><br />
            <li><FontAwesomeIcon icon="phone" /> 2111 6725</li><br /><br />
            <li><FontAwesomeIcon icon="envelope" /> theledgemudjimba@mail.com</li><br /><br />
            <li><FontAwesomeIcon icon="map-marker-alt"/> 160 Mudjimba Beach Road</li>
          </ul>
        </ul>
      </div>

      <form id="fs-frm" name="simple-contact-form" acceptCharset="utf-8" action="https://formspree.io/f/xrgjebeo" method="post">
        <fieldset id="fs-frm-inputs">
          <label htmlFor="full-name">Full Name</label>
          <input type="text" name="name" id="full-name" placeholder="First and Last" required="" />
          <label htmlFor="email-address">Email Address</label>
          <input type="email" name="_replyto" id="email-address" placeholder="Email@Mail.com" required="" />
          <label htmlFor="message">Message</label>
          <textarea rows="5" name="message" id="message" placeholder="" required=""></textarea>
          <input type="hidden" name="_subject" id="email-subject" value="Contact Form Submission" />
        </fieldset>
        <Button Text = 'Submit' width = '100%' type="submit" value="Submit"/>
      </form>


    </div>
  );
};

export default MyForm;